<template>
  <div class="UseDailyPracticeRecord"  :style="{minHeight: screeHeight+'px'}">
    <div class="Previous_left">
      <div class="Previous_module">分类</div>
      <a-menu v-model="selectId"  mode="inline" style="width: 100%">
        <a-menu-item
            v-for="(item, index) in templateList"
            :key="index"
            @click="selectMenu(item)"
        >
          <span v-if="item == 'DOCUMENT'">
            文献指南
          </span>
          <span v-if="item == 'CASE'">
            病例夹
          </span>
        </a-menu-item>
      </a-menu>
    </div>
    <div class="Previous_right">
      <div class="log">
        <a-spin :spinning="spinning">
          <div class="search">
            <div>
              操作日期&nbsp;
              <a-range-picker v-model="searchValue" class="rangePicker" @change="dateChange"/>
              <a-button style="margin-left: 20px" type="primary" @click="searchBtn">查询</a-button>
            </div>
            <a-button @click="expotExcel">
              <a-icon style="color: #00A854;" type="file-excel"/>
              批量导出
            </a-button>

          </div>
          <div class="table">
            <!-- :columns="selectId == 0? columns:columns2" -->
            <a-table
                :columns="columns"
                :data-source="tableData"
                :pagination="false"
                :rowKey="record=>record.id"
                :scroll="{ x: 1500}">
              <!-- <span slot="location" slot-scope="text, record">
                {{ returnTitle(record.location) }}
              </span>                 :rowKey="record=>record.id"
              <span slot="contentType" slot-scope="text, record">
                {{ findValueByKey(content_type, record.contentType) }}
              </span>
              <span slot="channel" slot-scope="text, record">
                {{ record.channel !== null ? findValueByKey(channel, record.channel) : '未知' }}
              </span>
              <span slot="targetTitle" slot-scope="text, record" :title="record.targetTitle || record.title" style="overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp:2;-webkit-box-orient: vertical;">
                {{ record.targetTitle || record.title}}
              </span>
              <span slot="content_value" slot-scope="text, record" :title="record.content" style="overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp:2;-webkit-box-orient: vertical;">
                {{ record.content}}
              </span>
              <span slot="operation" slot-scope="text, record">
                          <a-popconfirm
                              title="确定删除?"
                              ok-text="是"
                              cancel-text="否"
                              @confirm="delBtn(record.id)"
                          >
            <a >删除</a>
            </a-popconfirm>
              </span> -->
            </a-table>
            <div class="pageView">
              <span class="pageLeft">共 {{ pagination.total }} 条记录 第 {{
               pagination.current
               }} / {{ Math.ceil(pagination.total / pagination.pageSize) }} 页</span>
              <a-pagination v-model="pagination.current"
                            :page-size-options="pageSizeOptions"
                            :page-size.sync="pagination.pageSize"
                            :total="pagination.total"
                            show-quick-jumper show-size-changer
                            @change="pageChange"
                            @showSizeChange="pageChange"/>
            </div>
          </div>
        </a-spin>
      </div>

    </div>
  </div> 
</template>

<script>
import {
  getEmailType,getEmailRecord,getEmailExcel
} from "@/service/operationLog";
export default {
  data(){
    return{
      screeHeight: document.body.clientHeight-64-15,
      templateList:[],
      type:'',
      tableData:[],
      columns: [
        {
          title: '姓名',
          align: 'center',
          dataIndex: 'name',
        }, {
          title: '医院',
          align: 'center',
          dataIndex: 'hospitalName',
        },,{
          title: '科室',
          dataIndex: 'hospitalOffices',
          align: 'center',
        },
        {
          title: '职称',
          dataIndex: 'jobTitle',
          align: 'center',
        },
        {
          title: '标题',
          align: 'center',
          dataIndex: 'title',
        },
        {
          title: '发送地址',
          align: 'center',
          dataIndex: 'email',
        }, 
        {
          title: '操作时间',
          align: 'center',
          dataIndex: 'createdTime',
        },
      ],
      columns2: [
        {
          title: '姓名',
          align: 'center',
          dataIndex: 'name',
        }, {
          title: '医院',
          align: 'center',
          dataIndex: 'hospitalName',
        },,{
          title: '科室',
          dataIndex: 'hospitalOffices',
          align: 'center',
        },
        {
          title: '职称',
          dataIndex: 'jobTitle',
          align: 'center',
        },
        {
          title: '标题',
          align: 'center',
          dataIndex: 'title',
        },
        {
          title: '发送地址',
          align: 'center',
          dataIndex: 'email',
        }, 
        {
          title: '操作时间',
          align: 'center',
          dataIndex: 'createdTime',
        },
      ],
      pageSizeOptions: ['10', '30', '50'],
      selectId: [0],
      searchValue: [],
      spinning: false,
      pagination: {
        total: 0,
        pageSize: 10,
        current: 1,
      },
    }
  },
  mounted() {
    this.getLeftTemplate()
  },
  methods:{
    //获取左侧菜单列表
    async getLeftTemplate() {
      // 菜单
      const response = await getEmailType()
      if (response.statuscode === 200) {
        // console.log("文献管理菜单",response)
        this.templateList = response.data
        this.type = response.data[0]
        // 加载第一个菜单的数据
        const data = {
            // 写到获取列表文献指南
            startTime:"",
            endTime:"",
            pageNo:1,
            pageSize:10,
            type:this.type
        }
        const res = await getEmailRecord(data)
        if (res.code === 0) {
          // console.log("文献管理data",res)
          this.tableData = res.data
          this.pagination.total = res.count
        }
      }
    },
    // 菜单切换
    async selectMenu(item){
      this.searchValue = []
      this.pagination.pageSize = 10
      this.pagination.current = 1
      this.type = item
      this.getDocumentData()
    //   if(item == 'DOCUMENT'){
    //     this.type = item
    //     this.getDocumentData()
    //   }else if (item == 'CASE'){
    //     this.getCaseData()
    //   }
    },
    // 文献指南data
    async getDocumentData(){
      const data = {
        // 写到获取列表文献指南
        startTime:this.searchValue[0],
        endTime:this.searchValue[1],
        pageNo:this.pagination.current,
        pageSize:this.pagination.pageSize,
        type:this.type
      }
      const response = await getEmailRecord(data)
      if (response.code === 0) {
        // console.log("文献管理data",response)
        this.tableData = response.data
        this.pagination.total = response.count
      }
    },
    // 病例夹data
    // async getCaseData(){
    //   const data = {
    //     // 写到获取列表文献指南
    //     startTime:this.searchValue[0],
    //     endTime:this.searchValue[1],
    //     pageNo:this.pagination.current,
    //     pageSize:this.pagination.pageSize,
    //     type:this.type
    //   }
    //   const response = await getEmailRecord(data)
    //   if (response.code === 0) {
    //     console.log("病例夹data",response)
    //     this.tableData = response.data
    //     this.pagination.total = response.count
    //   }
    // },
    //日历
    dateChange(date, dateString) {
    //   this.pagination.current = 1
      this.searchValue = dateString
      this.pagination.pageSize = 10
      this.pagination.current = 1
      this.getDocumentData()
    //   if(this.selectId == 0){
    //     this.getDocumentData()
    //   }else if(this.selectId == 1){
    //     this.getCaseData()
    //   }
    },
    searchBtn() {
    //   this.getData()
    },
    // 导出
    async expotExcel(){
      var ExelName = ""
      if (this.type == "DOCUMENT"){
        ExelName = "文献指南发送邮件记录"
      }else if(this.type == "CASE"){
        ExelName = "病例夹发送邮件记录"
      }
      const data = {
        type:this.type,
        startTime:this.searchValue[0],
        endTime:this.searchValue[1],
        ExelName:ExelName
      }
      const response = await getEmailExcel(data)
    //   if(this.selectId == 0){
    //   }
    //   else if(this.selectId == 1){
    //     const data = {
    //      type:'CASE',
    //      startTime:this.searchValue[0],
    //      endTime:this.searchValue[1],
    //      ExelName:"病例夹发送邮件记录"
    //     }
    //     const response = await getEmailExcel(data)
    //   }

    },
    // 页码
    pageChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
    //   if(this.selectId == 0){
    //     this.getDocumentData()
    //   }else if(this.selectId == 1){
    //     this.getCaseData()
    //   }
    this.getDocumentData()
    },
  }
}
</script>

<style lang="scss" scoped>
.ant-menu {
  border: none;
}

.UseDailyPracticeRecord {
  margin: -15px -15px;
  display: flex;
  justify-content: space-between;

  .Previous_left {
    flex: 1;
    //background-color: #ee7474;
    .Previous_module {
      color: black;
      font-weight: bolder;
      margin-left: 25px;
      margin-top: 10px;
    }

  }

  .Previous_right {
    border-left: 15px solid #F0F2F5;
    width: 84%;

    .Previous_right_top {
    }

    .Previous_right_title {
      width: 98%;
      margin: auto;
      font-weight: bold;
      color: #333333;
      padding-top: 15px;
    }

    .top_cont {
      width: 98%;
      margin: auto;
      display: flex;
      justify-content: space-between;
    }

    .EduRec-top-input {
      height: 50px;
      padding-top: 15px;
    }

    .activity-table {
      width: 98%;
      margin: auto;
      padding-top: 20px;
    }

    .pageView {
      display: flex;
      justify-content: space-between;
      z-index: 3;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .pageLeft {
      font-size: 15px;
      color: #929292
    }
  }
}

.log {
  background-color: #f0f2f5;

  .search {
    background-color: #fff;
    width: 100%;
    height: 64px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .table {
    background-color: #fff;
    padding: 20px;
  }
}

.pageView {
  display: flex;
  justify-content: space-between;
  z-index: 3;
  margin-top: 20px;
  margin-bottom: 20px;
}
::v-deep .ant-table-thead>tr>th {
  font-weight: bold;

}
.pageLeft {
  font-size: 15px;
  color: #929292
}
</style>